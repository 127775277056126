import React from "react";
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg'

const TechnicalComponent = () =>{
    return(
        <div className="business-analyst-section">
        <div className="full-container">
        <div className="home-section-textIcone">
                <TitleIcone className="home-section-titleIcone"/>
            <p className="business-analyst-section-title">
            Technical writer
            </p>
            </div>
            <p className="business-analyst-section-description">
            Our skilled wordsmiths will create material that distills 
            technical knowledge for every user base with simplicity and 
            clarity. Their main focus is to make information filled with 
            complex terminology and technical jargon accessible to your audience.
            </p>
            <p className="business-analyst-section-description">
            Our technical writers are geared towards giving the users 
            information clearly and succinctly. You will be given access 
            to professional technical writers that will do your product justice.
            </p>

        </div>
    </div>
    )
}
export default TechnicalComponent 
