import React from "react";
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg'

const FrontendComponent = () =>{
    return(
        <div className="business-analyst-section">
        <div className="full-container">
        <div className="home-section-textIcone">
                <TitleIcone className="home-section-titleIcone"/>
            <p className="business-analyst-section-title">
            Front-end engineer
            </p>
            </div>
            <p className="business-analyst-section-description">
            Our front-end developers will architect and develop 
            applications to meet your requirements. Among other things, 
            front-end developers ensure that online design and user experience 
            objectives are aligned, optimize web pages for optimal efficiency, 
            and maintain brand consistency across all websites.
            </p>
            <p className="business-analyst-section-description">
            To guarantee that all components of web creation are consistent, 
            front-end developers are required to work in teams with back-end developers, 
            visual designers, and user experience designers. They possess exceptional 
            interpersonal and communication abilities.
            </p>
        </div>
    </div>
    )
}
export default FrontendComponent