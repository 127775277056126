import React from "react";
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg'

const BackendComponent = () =>{
    return(
        <div className="business-analyst-section">
        <div className="full-container">
        <div className="home-section-textIcone">
                <TitleIcone className="home-section-titleIcone"/>
            <p className="business-analyst-section-title">
            Back-end engineer
            </p>
            </div>
            <p className="business-analyst-section-description">
            Databases, scripting, backend logic, APIs, and application 
            architecture are all part of backend development. The backend 
            development is everything users can’t see, but that powers 
            everything that happens on a website.
            </p>
            <p className="business-analyst-section-description">
            To provide comprehensive digital solutions for company needs, 
            our backend developers will collaborate with front-end developers, 
            full-stack developers, programmers, or UX specialists. They ensure that 
            the application is scalable and functional.
            </p>
            <p className="business-analyst-section-description">
            They're also in charge of maintaining and testing current 
            back-end components to guarantee they're as quick and efficient 
            as possible. They will also be responsible for data storage, which 
            involves a thorough understanding of data security and compliance.
            </p>
        </div>
    </div>
    )
}
export default BackendComponent