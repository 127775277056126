import React from "react";
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg'

const DevOpsComponent = () =>{
    return(
        <div className="business-analyst-section">
        <div className="full-container">
        <div className="home-section-textIcone">
                <TitleIcone className="home-section-titleIcone"/>
            <p className="business-analyst-section-title">
            DevOps
            </p>
            </div>
            <p className="business-analyst-section-description">
            Our DevOps engineers will simplify the process by bridging 
            the gap between the activities required to swiftly alter an 
            application and the tasks required to keep it reliable.
            </p>
            <p className="business-analyst-section-description">
            They are all about bringing processes together and automating 
            them and are crucial in bringing code, application maintenance, 
            and application administration together.
            </p>
            <p className="business-analyst-section-description">
            All of these responsibilities necessitate a thorough 
            understanding of not only development life cycles, 
            but also DevOps culture, including its philosophy, 
            methods, and tools. Our DevOps engineers understand 
            the fundamentals of application development and delivery.
            </p>
        </div>
    </div>
    )
}
export default DevOpsComponent