import React from "react";
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg'

const ProductOunerComponent = () =>{
    return(
        <div className="business-analyst-section">
        <div className="full-container">
        <div className="home-section-textIcone">
                <TitleIcone className="home-section-titleIcone"/>
            <p className="business-analyst-section-title">
            Product Owner
            </p>
            </div>
            <p className="business-analyst-section-description">
            Our product owners will be able to bridge the gap between 
            product development and strategy. They are in charge of the 
            product backlog, sprint planning, and answering inquiries from 
            developers as needed.
            </p>
            <p className="business-analyst-section-description">
            They specialize in negotiating priorities across recurring 
            stakeholder factions and then work with developers to ensure 
            that the needs are met. As stakeholders, you will be able to 
            have productive communication and a deep sense of understanding 
            of your objectives regarding the development process.
            </p>
            <p className="business-analyst-section-description">
            Our product owners are all about making tough decisions 
            and given their several years of experience, they will 
            offer their insights to the best of your business interests.
            </p>
        </div>
    </div>
    )
}
export default ProductOunerComponent