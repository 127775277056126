import { Link } from "gatsby";
import React from "react";
import ThreePersonIcon from '../../../static/assets/threePerson.svg'
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg'
const BusinessDeveloperTeamComponent = (props) =>{
    const {buseinessDeveloperTeamTitle} = props;
    return(
        <div className="full-container">
            <div className="business-section">
            <div className="home-section-textIcone">
                        <TitleIcone className="home-section-titleIcone"/>
                <h3 className="business-section-title">Hire the right team for you SaaS Business Development</h3>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="business-section-wrapper">
                            <div className="business-section-wrapper-header">
                                <ThreePersonIcon/>
                                <p className="business-section-wrapper-header-title">3 Person Team</p>
                            </div>
                            <p className="business-section-description">Maintain and enhance your product with a team of 3. In addition to cost-effectiveness, this small team offers the flexibility to quickly implement any ever-changing requirements.</p>
                            <div className="business-section-list-section">
                            <p className="business-section-list-title"> This team includes:</p>
                            <ul>
                                <li className="business-section-list"> ¼ Time UX/UI Designer</li>
                                <li className="business-section-list"> ¼ Time Product Manager</li>
                                <li className="business-section-list"> ¼ Time DevOps Engineer</li>
                                <li className="business-section-list"> ¼ Time QA Engineer</li>
                                <li className="business-section-list">2 Fullstack Engineers</li>
                            </ul>
                            </div>
                            <Link className="business-section-button" to="/contact">Get a Quote</Link>
                        </div>
                    </div>
                    <div className="col-md-4">
                    <div className="business-section-wrapper">
                            <div className="business-section-wrapper-header">
                                <ThreePersonIcon/>
                                <p className="business-section-wrapper-header-title">6 Person Team</p>
                            </div>
                            <p className="business-section-description">Build your MVP with a team of 6 fully dedicated to your product’s successful market entry. We make sure this team offers quality and fits your budget.</p>
                            <div className="business-section-list-section">
                            <p className="business-section-list-title"> This team includes:</p>
                            <ul>
                                <li className="business-section-list">  ½ UX/UI Designer</li>
                                <li className="business-section-list">½ Product Manager</li>
                                <li className="business-section-list"> ½ DevOps Engineer</li>
                                <li className="business-section-list"> ½ QA Engineer</li>
                                <li className="business-section-list">4 Fullstack Engineers</li>
                                <li className="business-section-list">Bonus: ½ Project Manager</li>
                            </ul>
                            </div>
                            <Link className="business-section-button"  to="/contact">Get a Quote</Link>
                        </div>
                    </div>
                    <div className="col-md-4">
                    <div className="business-section-wrapper">
                            <div className="business-section-wrapper-header">
                                <ThreePersonIcon/>
                                <p className="business-section-wrapper-header-title">9 Person Team</p>
                            </div>
                            <p className="business-section-description">For ambitious projects with a bigger budget and more demanding tasks, choose our team of 9 experts driven by excellence.</p>
                            <div className="business-section-list-section">
                            <p className="business-section-list-title"> This team includes:</p>
                            <ul>
                                <li className="business-section-list"> 1 UX/UI Designer</li>
                                <li className="business-section-list">1 Engineering Manager</li>
                                <li className="business-section-list">5 Fullstack Engineers</li>
                                <li className="business-section-list">1 DevOps Engineer</li>
                                <li className="business-section-list">1 QA Engineer</li>
                                <li className="business-section-list">Bonus: Project Manager, Product Manager</li>
                            </ul>
                            </div>
                            <Link className="business-section-button"  to="/contact">Get a Quote</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BusinessDeveloperTeamComponent;