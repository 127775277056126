import React,{useState} from "react";
import { Autoplay, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-coverflow';
import BusinessAnalys from "./businessAnalystComponent";
import ProductOunerComponent from "./productOunerComponent";
import DesignerComponent from "./designerComponent";
import TechnicalComponent from "./technicalComponent";
import ProductManagerComponent from "./productManagerComponent";
import FrontendComponent from "./frontendComponent";
import BackendComponent from "./backendComponent";
import DevOpsComponent from "./devopsComponent";
import QaComponent from "./qaComponent";
import ScrumMasterComponent from "./scrumMasterComponent";
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg'

const OurExpertComponent = (props) =>{
    const {expertButtonItems} = props
    const [isActive, setisActive] = useState();

    return(
        <div className="expert-section">
          <h1 className="expert-section-title">Experienced professionals make all the difference</h1>
          <p className="expert-section-subtitle">Contact us now to expereince a dynamic team collaboration!</p>
            <div className="expert-section-swiper"> 
            <div className="full-container">
            <div className="home-section-textIcone expert-section-swiper-icon">
                        <TitleIcone className="home-section-titleIcone"/>
                        <h3 className="expert-section-swiper-title">Our Experts</h3>
                  </div>
              <div className="expert-section-swiper-wrapper">
                <Swiper
                slidesPerView={'2'}
                modules={[Autoplay]}
                grabCursor={true}
                // centeredSlides={true}
                // spaceBetween={'20'}
                pagination={{
                  clickable:true,
                  renderBullet: function(index,className){
                    return '<span class="' + className + '">' + (index + 1  ) + '</span>';
                  },
                }}
                autoHeight={true}
                autoplay={{
                  delay: 2500,
                }}
                breakpoints={{
                  768:{
                    slidesPerView:5
                  }
                }}
                loop
                >
                <ul className="list-test">
                {expertButtonItems &&
              expertButtonItems.map((item, index) => {
                // console.log(index);
                return (
                  <li
                    key={index}
                    // className={`${
                    //   index === isActive
                    //     ? 'product-development-section_activeListItem'
                    //     : 'product-development-section-list-item'
                    // }`}
                  >
                    <SwiperSlide>
                    <button
                      onClick={() => {
                        setisActive(index);
                      }}
                      className={`${
                        index === isActive
                          ? 'expert-section_activeButtonList'
                          : 'expert-section-list-button'
                      }`}
                    >
                      <span className="expert-section-icon">{item.icon}</span>
                      <span>{item.text}</span>
                    </button>
                    </SwiperSlide>
                  </li>
                );
              })}
                </ul>
                </Swiper>
                </div>
                </div>
                </div>
                <div >
                  {isActive === 0 && (
                    <BusinessAnalys/>
                  )
                  }
                   {isActive === 1 && (
                    <ProductOunerComponent/>
                  )
                  }
                    {isActive === 2 && (
                      <DesignerComponent/>
                  )
                  }
                  {isActive === 3 && (
                      <TechnicalComponent/>
                  )
                  }
                   {isActive === 4 && (
                      <ProductManagerComponent/>
                  )
                  }
                   {isActive === 5 && (
                      <FrontendComponent/>
                  )
                  }
                   {isActive === 6 && (
                      <BackendComponent/>
                  )
                  }
                   {isActive === 7 && (
                      <DevOpsComponent/>
                  )
                  }
                  {isActive === 8 && (
                      <QaComponent/>
                  )
                  }
                  {isActive === 9 && (
                      <ScrumMasterComponent/>
                  )
                  }
                </div>
            </div>
    )
}
export default OurExpertComponent