import React from 'react';
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
const IndustryComponent = props => {
  const {
    industryTitle,
    industrySubtitle,
    industryProps,
    industryFintech,
    industryRetail,
    industrySocial,
    industryEntertiment,
    industryMarTech,
    PropImage,
    FintechImage,
    RetailImage,
    SocialTechImage,
    EntertainmentImage,
    MarTechImage,
    industrySubtext,
    industryListItem,
  } = props;
  return (
    <div className='industry-section'>
      <div className='full-container'>
        <div className='home-section-textIcone'>
          <TitleIcone className='home-section-titleIcone' />
          <h2 className='industry-section-title'>{industryTitle}</h2>
        </div>
        <p className='industry-section-subtitle'>{industrySubtitle}</p>
        <div className='row'>
          <div className='col-md-4'>
            <GatsbyImage
              alt='prop'
              image={PropImage}
              className='industry-section-image'
            />
            <p className='industry-section-descriptioon'>{industryProps}</p>
          </div>
          <div className='col-md-4'>
            <GatsbyImage
              alt='fintech'
              image={FintechImage}
              className='industry-section-image'
            />
            <p className='industry-section-descriptioon'>{industryFintech}</p>
          </div>
          <div className='col-md-4'>
            <GatsbyImage
              alt='retail'
              image={RetailImage}
              className='industry-section-image'
            />
            <p className='industry-section-descriptioon'>{industryRetail}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4'>
            <GatsbyImage
              alt='socialTech'
              image={SocialTechImage}
              className='industry-section-image'
            />
            <p className='industry-section-descriptioon'>{industrySocial}</p>
          </div>
          <div className='col-md-4'>
            <GatsbyImage
              alt='entertainment'
              image={EntertainmentImage}
              className='industry-section-image'
            />
            <p className='industry-section-descriptioon'>
              {industryEntertiment}
            </p>
          </div>
          <div className='col-md-4'>
            <GatsbyImage
              alt='martech'
              image={MarTechImage}
              className='industry-section-image'
            />
            <p className='industry-section-descriptioon'>{industryMarTech}</p>
          </div>
        </div>
      </div>
      <div className='industry-section-wrapper'>
        <div className='industry-section-wrapper-text'>
          <div className='home-section-textIcone'>
            <TitleIcone className='home-section-titleIcone' />
            <p className='industry-section-wrapper-subtext'>
              {industrySubtext}
            </p>
          </div>
          <p
            className='industry-section-wrapper-list-item'
            dangerouslySetInnerHTML={{
              __html: industryListItem?.replaceAll(
                'Motomtech',
                'UpTech Sh.p.k'
              ),
            }}
          ></p>
        </div>
      </div>
      <div className='industry-section-link'>
        <Link class='who-we-work-section-link service' href='/contact'>
          Contact Us
        </Link>
      </div>
    </div>
  );
};
export default IndustryComponent;
