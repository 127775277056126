import React from 'react';
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg';

const QaComponent = () => {
  return (
    <div className='business-analyst-section'>
      <div className='full-container'>
        <div className='home-section-textIcone'>
          <TitleIcone className='home-section-titleIcone' />
          <p className='business-analyst-section-title'>QA</p>
        </div>
        <p className='business-analyst-section-description'>
          Our Quality Assurance engineers thrive on being detailed, organized,
          and managing priorities. They will comprehend the technical aspects
          and commercial objectives, as well as effectively interact with
          consumers, managers, and developers.
        </p>
        <p className='business-analyst-section-description'>
          UpTech’s Sh.P.K Software Quality Assurance Engineers are also able to
          ensure that the finished product not only adheres to company and
          regulatory criteria but also reaches the consumer market on time.
        </p>
        <p className='business-analyst-section-description'>
          Because delays may be very costly to a business, our software quality
          assurance engineer will collaborate closely with all departments to
          ensure that the software project is completed on time and under
          budget.
        </p>
      </div>
    </div>
  );
};
export default QaComponent;
