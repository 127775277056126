import React from "react";
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg'

const BusinessAnalys = () =>{
    return(
        <div className="business-analyst-section">
            <div className="full-container">
            <div className="home-section-textIcone">
                <TitleIcone className="home-section-titleIcone"/>
                <p className="business-analyst-section-title">
                Business Analyst
                </p>
                </div>
                <p className="business-analyst-section-description">
                Change is the norm, fierce competition is the driver, 
                and lean thinking is the latest trend. Our business analysts 
                realize that, as they offer a mathematical examination of your
                business in terms of increased revenues, cost savings, increased 
                productivity, less personnel turnover, and reduced risk exposure. 
                </p>
                <p className="business-analyst-section-description">
                They are detail-oriented professionals who are high-performers 
                and have the positional power to establish strong relationships and 
                a comprehensive grasp of the business. They are strategic and provide 
                a clear vision for the whole team.
                </p>
                <p className="business-analyst-section-description">
                Their capabilities will ensure the team will meet business expectations 
                by including effective and targeted project management. Collaboration and 
                respect between the business and IT groups, great project leadership, and 
                high-performing teams will give you the desired results on your dream project.
                </p>
            </div>
        </div>
    )
}
export default BusinessAnalys;

