import React from 'react';
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg';

const ProductManagerComponent = () => {
  return (
    <div className='business-analyst-section'>
      <div className='full-container'>
        <div className='home-section-textIcone'>
          <TitleIcone className='home-section-titleIcone' />
          <p className='business-analyst-section-title'>Product manager</p>
        </div>
        <p className='business-analyst-section-description'>
          UpTech Sh.P.K offers brilliant product managers that are more than
          capable of setting goals, defining success, and motivating their teams
          to their best potential. They only accept the best from their team as
          they really know the lay of the land.
        </p>
        <p className='business-analyst-section-description'>
          They are forward thinkers, highly influential, and can walk people
          through the rationale behind a decision. Product managers anticipate,
          identify, and address obstacles that are preventing their team from
          delivering great products, and they give the product team ownership
          and agency to generate exceptional results.
        </p>
      </div>
    </div>
  );
};
export default ProductManagerComponent;
