import React from "react";
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg'

const ScrumMasterComponent = () =>{
    return(
        <div className="business-analyst-section">
        <div className="full-container">
        <div className="home-section-textIcone">
                <TitleIcone className="home-section-titleIcone"/>
            <p className="business-analyst-section-title">
            SCRUM master
            </p>
            </div>
            <p className="business-analyst-section-description">
            Our SCRUM masters can tackle complex adaptation challenges, 
            while productively and creatively delivering products of the 
            highest possible value. They will create self-organizing teams 
            that are flexible and productive during sprints.
            </p>
            <p className="business-analyst-section-description">
            Scrum teams led by the scrum master iterate and incrementally 
            deliver products, maximizing possibilities for feedback. A good 
            SCRUM master is able to actually listen, be observative, and will 
            definitely lead by example. He takes pride in the team’s self-organization 
            capabilities and values rhythm.
            </p>
        </div>
    </div>
    )
}
export default ScrumMasterComponent