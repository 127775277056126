import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

const CreateTeamComponent = props => {
  const { crateTeamImage, crateTeamTitle, crateTeamDescription } = props;
  return (
    <div className='crate-team-section'>
      <div className='row'>
        <div className='col-md-6'>
          <GatsbyImage
            image={crateTeamImage}
            className='crate-team-section-image'
          />
        </div>
        <div className='col-md-6'>
          <div className='crate-team-section-wrapper'>
            <p className='crate-team-section-wrapper-title'>{crateTeamTitle}</p>
            <p
              className='crate-team-section-wrapper-description'
              dangerouslySetInnerHTML={{
                __html: crateTeamDescription?.replaceAll(
                  'Motomtech',
                  'UpTech Sh.p.k'
                ),
              }}
            ></p>
            <Link className='crate-team-section-link' to='/contact'>
              Get a Quote
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateTeamComponent;
