import React from 'react';
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg';

const DesignerComponent = () => {
  return (
    <div className='business-analyst-section'>
      <div className='full-container'>
        <div className='home-section-textIcone'>
          <TitleIcone className='home-section-titleIcone' />
          <p className='business-analyst-section-title'>UX/UI designer</p>
        </div>
        <p className='business-analyst-section-description'>
          UpTech Sh.P.K offers a fantastic team of UX/UI designers. They make
          sure the product is inclusive and accessible. With the never-ending
          technological advancements that are happening, people want to
          experience the best of it, on a daily basis.
        </p>
        <p className='business-analyst-section-description'>
          Our excellent User Interface will draw people to your project right
          away, while the User Experience will leave a lasting impression on
          your users. As a result, if you want your app to be successful, it is
          imperative that both of these goals are met meticulously.
        </p>
        <p className='business-analyst-section-description'>
          Our designers are able to create bold graphics, smooth transitions,
          gifs, and aesthetic elements keeping it fresh while reducing the risk
          of looking outdated.
        </p>
      </div>
    </div>
  );
};
export default DesignerComponent;
