import React, {useState} from "react";
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg'

const ServicesProductDevelopmentComponent = (props) =>{
    const { buttonItems , mvpDescription,productDescription,productDeveloperTitle} = props;
    const [isActive, setisActive] = useState(0);
    return(
        <div className="full-container">
            <div className="product-development-section">
            <div className="home-section-textIcone">
                        <TitleIcone className="home-section-titleIcone"/>
                <h5 className="product-development-section-title">{productDeveloperTitle}</h5>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <ul>
                        {buttonItems &&
              buttonItems.map((item, index) => {
                // console.log(index);
                return (
                  <li
                    key={index}
                    className={`${
                      index === isActive
                        ? 'product-development-section_activeListItem'
                        : 'product-development-section-list-item'
                    }`}
                  >
                    <button
                      onClick={() => {
                        setisActive(index);
                      }}
                      className={`${
                        index === isActive
                          ? 'product-development-section_activeButtonList'
                          : 'product-development-section-list-button'
                      }`}
                    >
                      <span>{item}</span>
                    </button>
                  </li>
                );
              })}
                        </ul>
                    </div>
                    {isActive === 0 &&(
                    <div className="col-md-6">
                          <div className="home-section-textIcone">
                        <TitleIcone className="home-section-titleIcone"/>
                        <p className="product-development-section-text" dangerouslySetInnerHTML={{ __html: mvpDescription }}></p>
                        </div>
                    </div>
                    )}
                     {isActive === 1 &&(
                    <div className="col-md-6">
                          <div className="home-section-textIcone">
                        <TitleIcone className="home-section-titleIcone"/>
                        <p  className="product-development-section-text" dangerouslySetInnerHTML={{ __html: productDescription }}></p>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    )
}
export default ServicesProductDevelopmentComponent