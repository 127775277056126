import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const OurProcessComponent = (props) =>{
    const {ourProcessTitle,ourProcessDescription,ourProcessImage} = props;
    return(
        <div className="full-container">
            <div className="our-process-section">
                <h6 className="our-process-section-title">{ourProcessTitle}</h6>
                <p className="our-process-section-description" dangerouslySetInnerHTML={{ __html: ourProcessDescription }}></p>
                <div className="our-process-section-image-section">
                <GatsbyImage image={ourProcessImage} className="our-process-section-image"/>
                </div>
            </div>
        </div>
    )
}
export default OurProcessComponent;