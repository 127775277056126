import React, {useState} from "react";
import UserTestingIcone from '../../../static/assets/UX/userTesting.svg';
import MiroIcone from '../../../static/assets/UX/miro.svg';
import ClickUpIcone from '../../../static/assets/UX/chlickUp.svg';
import AsanaIcone from '../../../static/assets/UX/asana.svg';
import FigmaIcone from '../../../static/assets/UX/figma.svg';
import BootstrapIcone from '../../../static/assets/UX/bootstrap.svg';
import MaterialUiIcone from '../../../static/assets/UX/materialUI.svg';
import AntiDesignIcone from '../../../static/assets/UX/antiDesign.svg';
import HtmlIcone from '../../../static/assets/fronEnd/html.svg';
import SassIcone from '../../../static/assets/fronEnd/sass.svg';
import LessIcone from '../../../static/assets/fronEnd/less.svg';
import JavaScriptIcone from '../../../static/assets/fronEnd/javaScript.svg';
import TypescriptIcone from '../../../static/assets/fronEnd/typeScript.svg';
import ReactIcone from '../../../static/assets/fronEnd/reactIcon.svg';
import VueJsIcone from '../../../static/assets/fronEnd/vueJs.svg';
import AngularIcone from '../../../static/assets/fronEnd/angular.svg'
import NodeJSIcone from '../../../static/assets/backEnd/node.svg';
import NestJSIcone from '../../../static/assets/backEnd/nest.svg';
import RubyIcone from '../../../static/assets/backEnd/ruby.svg';
import LaravelIcone from '../../../static/assets/backEnd/php.svg';
import MySQLIcone from '../../../static/assets/backEnd/sql.svg';
import PostGreSQLIcone from '../../../static/assets/backEnd/post.svg';
import PythonIcone from '../../../static/assets/backEnd/python.svg';
import NetIcone from '../../../static/assets/backEnd/net.svg';
import CIcone from '../../../static/assets/backEnd/c.svg';
import AWSIcone from '../../../static/assets/DevOps/aws.svg';
import AZUREIcone from '../../../static/assets/DevOps/azure.svg';
import GoogleCloudIcone from '../../../static/assets/DevOps/cloud.svg';
import DockerIcone from '../../../static/assets/DevOps/docker.svg';
import KubernetsIcone from '../../../static/assets/DevOps/kubernetes.svg';
import TerraformIcone from '../../../static/assets/DevOps/terraform.svg'
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg'
import PipelineIcon from '../../../static/assets/DevOps/pipelin.svg'
import {  Pagination,Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
const TechStackComponent = (props) =>{
    const {techTitle,careerPositionSubtitle} = props;
    const [isActive, setIsActive] = useState(0);
    return (
      <div className="tech-section">
        <div className="full-container">
          <div className="home-section-textIcone">
            <TitleIcone className="home-section-titleIcone" />
            <h2 className="solutions-section-text-title">{techTitle}</h2>
          </div>
          <p className="tech-section-subtitle">{careerPositionSubtitle}</p>
          <div className="career-position-section-nav">
            <div className="career-position-section-nav">
              <Swiper
                navigation={false}
                slidesPerView={2.5}
                pagination={false}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                breakpoints={{
                  768: {
                    slidesPerView: 5,
                  },
                }}
              >
                <ul className="career-position-section-nav-list">
                  <SwiperSlide>
                    <li className="career-position-section-nav-list-item">
                      <button
                        className={`${
                          0 === isActive
                            ? "career-position-section-button-active"
                            : "career-position-section-button"
                        }`}
                        onClick={() => {
                          setIsActive(0);
                        }}
                      >
                        UX/UI Design
                      </button>
                    </li>
                  </SwiperSlide>
                  <SwiperSlide>
                    <li className="career-position-section-nav-list-item">
                      <button
                        className={`${
                          1 === isActive
                            ? "career-position-section-button-active"
                            : "career-position-section-button"
                        }`}
                        onClick={() => {
                          setIsActive(1);
                        }}
                      >
                        Front-End
                      </button>
                    </li>
                  </SwiperSlide>
                  <SwiperSlide>
                    <li className="career-position-section-nav-list-item">
                      <button
                        className={`${
                          2 === isActive
                            ? "career-position-section-button-active"
                            : "career-position-section-button"
                        }`}
                        onClick={() => {
                          setIsActive(2);
                        }}
                      >
                        Back-end
                      </button>
                    </li>
                  </SwiperSlide>
                  <SwiperSlide>
                    <li className="career-position-section-nav-list-item">
                      <button
                        className={`${
                          3 === isActive
                            ? "career-position-section-button-active"
                            : "career-position-section-button"
                        }`}
                        onClick={() => {
                          setIsActive(3);
                        }}
                      >
                        DevOps
                      </button>
                    </li>
                  </SwiperSlide>
                </ul>
              </Swiper>
            </div>
          </div>
          {isActive === 0 && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <UserTestingIcone className="tech-section-icon" />
                  <p className="tech-section-description">UserTesting</p>
                </div>
                <div className="col-lg-3">
                  <MiroIcone className="tech-section-icon" />
                  <p className="tech-section-description">Miro</p>
                </div>
                <div className="col-md-3">
                  <ClickUpIcone className="tech-section-icon" />
                  <p className="tech-section-description">ClickUp</p>
                </div>
                <div className="col-lg-3">
                  <AsanaIcone className="tech-section-icon" />
                  <p className="tech-section-description">Asana</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <FigmaIcone className="tech-section-icon" />
                  <p className="tech-section-description">Figma</p>
                </div>
                <div className="col-lg-3">
                  <BootstrapIcone className="tech-section-icon" />
                  <p className="tech-section-description">Bootstrap</p>
                </div>
                <div className="col-md-3">
                  <MaterialUiIcone className="tech-section-icon" />
                  <p className="tech-section-description">Material UI</p>
                </div>
                <div className="col-lg-3">
                  <AntiDesignIcone className="tech-section-icon" />
                  <p className="tech-section-description">Ant Design</p>
                </div>
              </div>
            </div>
          )}
          {isActive === 1 && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <HtmlIcone className="tech-section-icon" />
                  <p className="tech-section-description">HTML 5</p>
                </div>
                <div className="col-lg-3">
                  <SassIcone className="tech-section-icon" />
                  <p className="tech-section-description">Sass</p>
                </div>
                <div className="col-md-3">
                  <LessIcone className="tech-section-icon" />
                  <p className="tech-section-description">Less</p>
                </div>
                <div className="col-lg-3">
                  <JavaScriptIcone className="tech-section-icon" />
                  <p className="tech-section-description">Javascript</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <TypescriptIcone className="tech-section-icon" />
                  <p className="tech-section-description">Typescript</p>
                </div>
                <div className="col-md-3">
                  <ReactIcone />
                  <p className="tech-section-description">React JS</p>
                </div>
                <div className="col-md-3">
                  <VueJsIcone className="tech-section-icon" />
                  <p className="tech-section-description">Vue JS</p>
                </div>
                <div className="col-lg-3">
                  <AngularIcone className="tech-section-icon" />
                  <p className="tech-section-description">Angular JS</p>
                </div>
              </div>
            </div>
          )}
          {isActive === 2 && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <NodeJSIcone className="tech-section-icon" />
                  <p className="tech-section-description">Node JS</p>
                </div>
                <div className="col-lg-3">
                  <NestJSIcone className="tech-section-icon" />
                  <p className="tech-section-description">Nest JS</p>
                </div>
                <div className="col-md-3">
                  <RubyIcone className="tech-section-icon" />
                  <p className="tech-section-description">Ruby on Rails</p>
                </div>
                <div className="col-lg-3">
                  <LaravelIcone className="tech-section-icon" />
                  <p className="tech-section-description">Lavarel (PHP)</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <MySQLIcone className="tech-section-icon" />
                  <p className="tech-section-description">MySQL</p>
                </div>
                <div className="col-lg-3">
                  <PostGreSQLIcone className="tech-section-icon" />
                  <p className="tech-section-description">PostGreSQL</p>
                </div>
                <div className="col-md-3">
                  <PythonIcone className="tech-section-icon" />
                  <p className="tech-section-description">Python</p>
                </div>
                <div className="col-lg-3">
                  <NetIcone className="tech-section-icon" />
                  <p className="tech-section-description">.NET</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <CIcone className="tech-section-icon" />
                  <p className="tech-section-description">C#</p>
                </div>
              </div>
            </div>
          )}
          {isActive === 3 && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <AWSIcone className="tech-section-icon" />
                  <p className="tech-section-description">AWS</p>
                </div>
                <div className="col-lg-3">
                  <AZUREIcone className="tech-section-icon" />
                  <p className="tech-section-description">AZURE</p>
                </div>
                <div className="col-md-3">
                  <GoogleCloudIcone className="tech-section-icon" />
                  <p className="tech-section-description">Google Cloud</p>
                </div>
                <div className="col-lg-3">
                  <DockerIcone className="tech-section-icon" />
                  <p className="tech-section-description">Docker</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <KubernetsIcone className="tech-section-icon" />
                  <p className="tech-section-description">Kubernetes</p>
                </div>
                <div className="col-lg-3">
                  <TerraformIcone className="tech-section-icon" />
                  <p className="tech-section-description">Terraform</p>
                </div>
                <div className="col-md-3">
                  <PipelineIcon className="tech-section-icon" />
                  <p className="tech-section-description">CI/CD Pipeline</p>
                </div>
                <div className="col-lg-3"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
}
export default TechStackComponent;