import React from 'react';
import IndustryComponent from '../component/Services/industryComponent';
import OfferComponent from '../component/Services/offerComponent';
import OurDedicatedComponent from '../component/Services/ourDedicatedComponent';
import ServicesHomeComponent from '../component/Services/servicesHomeComponent';
import TechStackComponent from '../component/Services/techStackComponent';
import SolutionsHomeComponent from '../component/solutionsHomeComponent';
import FooterComponent from '../component/footerComponent';
import { graphql } from 'gatsby';
import ServicesImage from '../images/services_banner.png';
import AppDevelopment from '../component/appDevelopmentComponent';
import Layout from '../component/layout';
import BusinessDeveloperTeamComponent from '../component/Services/businessDeveloperTeamComponent';
import CreateTeamComponent from '../component/Services/createTeamComponent';
import OurProcessComponent from '../component/Services/ourProcessComponent';
import ServicesProductDevelopmentComponent from '../component/Services/servicesProductDevelopmentComponent';
import OurExpertComponent from '../component/Services/ourExpertComponent';
import BusinessAnalystIcon from '../../static/assets/businessAnalystIconsvg.svg';
import ProductOwnerIcon from '../../static/assets/productOuner.svg';
import DesignerIcon from '../../static/assets/designIcon.svg';
import TechnicalIcon from '../../static/assets/technicalWriteIcon.svg';
import ProductManagerIcon from '../../static/assets/productManager.svg';
import FrontEndIcon from '../../static/assets/frontendIcon.svg';
import BackendIcon from '../../static/assets/backendIcon.svg';
import DevopsIcon from '../../static/assets/devopsIcon.svg';
import ScrumMasterIcon from '../../static/assets/scrumMasterIcon.svg';
import QaIcon from '../../static/assets/qaIcon.svg';
const buttonItems = ['Conceptualize your MVP', 'Enhance your Product'];
const expertButtonItems = [
  {
    text: 'Business Analyst',
    icon: <BusinessAnalystIcon />,
  },
  {
    text: 'Product Owner',
    icon: <ProductOwnerIcon />,
  },
  {
    text: 'UX/UI designer',
    icon: <DesignerIcon />,
  },
  {
    text: 'Technical writer',
    icon: <TechnicalIcon />,
  },
  {
    text: 'Product manager',
    icon: <ProductManagerIcon />,
  },
  {
    text: 'Front-end engineer',
    icon: <FrontEndIcon />,
  },
  {
    text: 'Back-end engineer',
    icon: <BackendIcon />,
  },
  {
    text: 'DevOps',
    icon: <DevopsIcon />,
  },
  {
    text: 'QA',
    icon: <QaIcon />,
  },
  {
    text: 'SCRUM master',
    icon: <ScrumMasterIcon />,
  },
];
const Services = props => {
  const {
    data: {
      wpPage: {
        seo,
        serviciesHeader: { servicesHomeDescription, servicesHomeTitle },
        homePageHeaderSection: { homeHeaderLearnButton },
        homePageServicesSection: {
          homePageServicesDescription,
          homePageServicesMobileDescription,
          homePageServicesMobileSalesforceDescription,
          homePageServicesMobileSalesforceTitle,
          homePageServicesMobileTitle,
          homePageServicesProductDescription,
          homePageServicesProductTitle,
          homePageServicesTitle,
          homePageServicesSubtitle,
          homePageServicesWebDescription,
          homePageServicesWebTitle,
        },
        ourWorkAgreedSection: {
          ourWorkAgreedListItem,
          ourWorkAgreedSubtitle,
          ourWorkAgreedImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: ourWorkAgreedImage },
            },
          },
        },
        ourProcess: {
          ourProcessServicesTitle,
          ourProcessServicesDescription,
          ourProcessServicesImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: ourProcessServicesImage },
            },
          },
        },
        serviceProductDevelopment: {
          serviceProductDevelopmentMvpText,
          serviceProductDevelopmentText,
          serviceProductDevelopmentTitle,
        },
        servicePageIndustriesSection: {
          servicePageIndustriesDescription,
          servicePageIndustriesEntertainmentImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: servicePageIndustriesEntertainmentImage,
              },
            },
          },
          servicePageIndustriesEntertainmentText,
          servicePageIndustriesFintechImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: servicePageIndustriesFintechImage,
              },
            },
          },
          servicePageIndustriesFintechText,
          servicePageIndustriesMartechImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: servicePageIndustriesMartechImage,
              },
            },
          },
          servicePageIndustriesMartechText,
          servicePageIndustriesProptechImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: servicePageIndustriesProptechImage,
              },
            },
          },
          servicePageIndustriesProptechText,
          servicePageIndustriesRetailImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: servicePageIndustriesRetailImage,
              },
            },
          },
          servicePageIndustriesRetailText,
          servicePageIndustriesSocialtechImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: servicePageIndustriesSocialtechImage,
              },
            },
          },
          servicePageIndustriesSocialtechText,
          servicePageIndustriesTitle,
          servicePageIndustriesSubtext,
          servicePageIndustriesLists,
        },
      },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout seo={seo} title={title}>
      <div className='services-section-shape'>
        <ServicesHomeComponent
          servicesBackground={ServicesImage}
          servicesHomeTitle={servicesHomeTitle}
          servicesDescription={servicesHomeDescription}
        />
        <SolutionsHomeComponent
          solutionsTitle={homePageServicesTitle}
          solutionsSubtitle={homePageServicesSubtitle}
          solutionsDescription={homePageServicesDescription}
          solutionsProductTitle={homePageServicesProductTitle}
          solutionsProductDescription={homePageServicesProductDescription}
          solutionsWebApplicaationTitle={homePageServicesWebTitle}
          solutionsWebApplicaationDescription={homePageServicesWebDescription}
          solutionsMobileApplicationtTitle={homePageServicesMobileTitle}
          solutionsMobileApplicationtDescription={
            homePageServicesMobileDescription
          }
          solutionsSalesforceTitle={homePageServicesMobileSalesforceTitle}
          solutionsSalesforceDescription={
            homePageServicesMobileSalesforceDescription
          }
          solutionsDescriptionLink={homeHeaderLearnButton}
          isServiiesPage={true}
        />
        <BusinessDeveloperTeamComponent />
        <CreateTeamComponent
          crateTeamImage={ourWorkAgreedImage}
          crateTeamTitle={ourWorkAgreedSubtitle}
          crateTeamDescription={ourWorkAgreedListItem}
        />
      </div>
      <OurExpertComponent expertButtonItems={expertButtonItems} />
      {/* <ServicesProductDevelopmentComponent
         buttonItems={buttonItems}
         productDeveloperTitle={serviceProductDevelopmentTitle}
         mvpDescription={serviceProductDevelopmentMvpText}
         productDescription={serviceProductDevelopmentText}
         /> */}
      <OurProcessComponent
        ourProcessTitle={ourProcessServicesTitle}
        ourProcessDescription={ourProcessServicesDescription}
        ourProcessImage={ourProcessServicesImage}
      />
      <TechStackComponent
        techTitle='Tech Stack'
        careerPositionSubtitle='We will pick the right technology stack based on the specifications of your project. Continuous learning keeps our teams up-to-date on the latest technologies in AI and Machine learning, Big Data, DevOps, Cloud, and software languages and development methodologies.'
      />
      <IndustryComponent
        industryTitle={servicePageIndustriesTitle}
        industrySubtitle={servicePageIndustriesDescription}
        industryProps={servicePageIndustriesProptechText}
        industryFintech={servicePageIndustriesFintechText}
        industryRetail={servicePageIndustriesRetailText}
        industrySocial={servicePageIndustriesSocialtechText}
        industryEntertiment={servicePageIndustriesEntertainmentText}
        industryMarTech={servicePageIndustriesMartechText}
        PropImage={servicePageIndustriesProptechImage}
        FintechImage={servicePageIndustriesFintechImage}
        RetailImage={servicePageIndustriesRetailImage}
        SocialTechImage={servicePageIndustriesSocialtechImage}
        EntertainmentImage={servicePageIndustriesEntertainmentImage}
        MarTechImage={servicePageIndustriesMartechImage}
        industrySubtext={servicePageIndustriesSubtext}
        industryListItem={servicePageIndustriesLists}
      />
      <FooterComponent />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "service-page" }) {
      seo {
        metaDesc
        title
      }
      serviciesHeader {
        servicesHomeDescription
        servicesHomeTitle
      }
      ourProcess {
        ourProcessServicesTitle
        ourProcessServicesDescription
        ourProcessServicesImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      serviceProductDevelopment {
        serviceProductDevelopmentMvpText
        serviceProductDevelopmentText
        serviceProductDevelopmentTitle
      }
      homePageFullCycleSection {
        homePageFullCycleAppDescription
        homePageFullCycleAppTitle
        homePageFullCycleBusinessDescription
        homePageFullCycleBusinessTitle
        homePageFullCycleDesignDescription
        homePageFullCycleDesignTitle
        homePageFullCycleDevelopmentDescription
        homePageFullCycleDevelopmentTitle
        homePageFullCycleProductDescription
        homePageFullCycleProductTitle
        homePageFullCyclePublishingDescription
        homePageFullCyclePublishingTitle
        homePageFullCycleQualityDescription
        homePageFullCycleQualityTitle
        homePageFullCycleTitle
      }
      homePageHeaderSection {
        homeHeaderTitle
        homeHeaderDescription
        homeHeaderLearnButton
        homeHeaderImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      homePageServicesSection {
        homePageServicesDescription
        homePageServicesMobileDescription
        homePageServicesMobileSalesforceDescription
        homePageServicesMobileSalesforceTitle
        homePageServicesMobileTitle
        homePageServicesProductDescription
        homePageServicesProductTitle
        homePageServicesTitle
        homePageServicesSubtitle
        homePageServicesWebDescription
        homePageServicesWebTitle
      }
      ourWorkAgreedSection {
        ourWorkAgreedListItem
        ourWorkAgreedSubtitle
        ourWorkAgreedImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      servicePageOfferSection {
        servicePageOfferDedicatedDescription
        servicePageOfferDedicatedList
        servicePageOfferDedicatedTitle
        servicePageOfferExtendedDescription
        servicePageOfferExtendedTitle
        servicePageOfferTitle
      }
      servicePageTeamSection {
        servicePageTeamBackendDescription
        servicePageTeamBackendTitle
        servicePageTeamBusinessAnalystDescription
        servicePageTeamBusinessAnalystTitle
        servicePageTeamDescription
        servicePageTeamDesignDescription
        servicePageTeamDesignTitle
        servicePageTeamDevopsDescription
        servicePageTeamDevopsTitle
        servicePageTeamFrontendDescription
        servicePageTeamFrontendTitle
        servicePageTeamImplementation
        servicePageTeamManageTitle
        servicePageTeamManagerDescription
        servicePageTeamProduct
        servicePageTeamProductDescription
        servicePageTeamProductTitle
        servicePageTeamQaDescription
        servicePageTeamQaTitle
        servicePageTeamTitle
        servicePageTeamWriterDescription
        servicePageTeamWriterTitle
      }
      servicePageIndustriesSection {
        servicePageIndustriesDescription
        servicePageIndustriesEntertainmentImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        servicePageIndustriesEntertainmentText
        servicePageIndustriesFintechImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        servicePageIndustriesFintechText
        servicePageIndustriesMartechImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        servicePageIndustriesMartechText
        servicePageIndustriesProptechImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        servicePageIndustriesProptechText
        servicePageIndustriesRetailImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        servicePageIndustriesRetailText
        servicePageIndustriesSocialtechImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        servicePageIndustriesSocialtechText
        servicePageIndustriesTitle
        servicePageIndustriesSubtext
        servicePageIndustriesLists
      }
    }
  }
`;
export default Services;
